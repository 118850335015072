import React, { useEffect, useState } from 'react';
import { Box,Grid, TableBody, TableCell, TableHead,Paper,TableContainer,Table,TableRow, Typography} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import TagService from '../API/TagService';
import ContactService from '../API/ContactService';
import { getICon } from '../API/AppIconService';

function ContactsSummary(props) {
    const [records,setRecords] = useState([]);
    const [contacts,setContacts]=useState([]);

    const fetchIcon=(props)=>{
        const {x,y,payload} = props;
        return (
            <svg  x={x-16} y={y} width="26" height="26" fill="red" viewBox="0 0 16 16">
                {getICon(payload.value)}
            </svg>
        );
    }

    useEffect(()=>{
        getdata();
        ContactService.listVirginContacts().then((response)=>{
            setContacts(response.data);
        })

    },[])

    const getdata = ()=>{
        TagService.listTagSummary().then((response)=>{
            setRecords(response.data);
        })
    }

    return (
        <Box container sx={{mt:2, mx:'auto', height: 75,width:800}}>
            <Grid container spacing={2}>
                <Grid >
                    <Typography>Contacts in Groups</Typography>
                    <BarChart width={400} height={240} data={records}
                        margin={{ top: 5, right: 30, left: 20,bottom: 5}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="categoryname"  tick={fetchIcon} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="contacts" fill="#42a5f5" />
                    </BarChart>
                </Grid>
                <Grid >
                    <Typography>Contacts yet to connect</Typography>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Moble</TableCell>
                                        <TableCell>Age</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        contacts.map((contact,i)=>{
                                            return(
                                                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                                                    <TableCell  >{contact.name}</TableCell>
                                                    <TableCell  >{contact.mobile}</TableCell>
                                                    <TableCell  >{contact.age}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
export default ContactsSummary;