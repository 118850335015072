import React,{useState} from 'react';
import PersonIcon from '@mui/icons-material/Person';
import {FormControl,Box, Typography} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Button, TextField,  Input, Stack,IconButton } from '@mui/material';
import { Outlet, useNavigate,createSearchParams } from 'react-router-dom';


function ContactSearch(props) {
    const navigate = useNavigate();
    const [byName,setByName] = useState("");
    const [byPhone,setByPhone] = useState("");

    const search = (e)=>{
        let criteria ={name:byName,phoneno:byPhone};
        navigate({pathname :"searchresult",
                  search: createSearchParams(criteria).toString()
                });
        e.preventDefault();
    }

    const clear = (e) =>{
        setByName("");
        setByPhone("");
    }

    return (
        <>
            <Box sx={{mt:2, mx:'auto',height: 75,width:800 }}>
                <Box container>
                <FormControl  sx={{ m: 1 }}>
                    <Input
                        value={byName}
                        onChange={(e)=>{setByName(e.target.value)}}
                        startAdornment={
                            <InputAdornment position="start">
                              <IconButton edge="start" >
                                {<PersonIcon />}
                              </IconButton>
                            </InputAdornment>
                          }
                        label="Name"
                    />
                </FormControl>
                <FormControl  sx={{ m: 1 }}>
                    <Input
                        value={byPhone}
                        onChange={(e)=>{setByPhone(e.target.value)}}
                        label="Phone"
                        startAdornment={
                            <InputAdornment position="start">
                              <IconButton edge="start" >
                                {<CallIcon />}
                              </IconButton>
                            </InputAdornment>
                          }
                    />
                </FormControl>
                <IconButton onClick={(e)=>{search(e)}}>
                  <SearchIcon />        
                </IconButton>
              
                </Box>
               
                <Typography variant='h6'>Search Result</Typography>
                <Outlet />
            </Box>
            
        </>
    );
}

export default ContactSearch;