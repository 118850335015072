import React, { useEffect,useState } from 'react';
import {Box,Typography,Grid,Chip} from '@mui/material';
import { CrisisAlert } from '@mui/icons-material';
import ObjectiveService from '../API/ObjectiveService';
import { Outlet,useNavigate,createSearchParams } from 'react-router-dom';


function ObjectiveAnalysis(props) {
    
    const [objectives,setObjectives]=useState([]);
   
    
    const navigate = useNavigate();
    /*
    const [totalIncome,setTotalIncome]=useState(0);
    const [totalExpense,setTotalExpense]=useState(0);
    */
    

    useEffect(()=>{
        ObjectiveService.listObjectivesForUser().then((response)=>{
            setObjectives(response.data);
        })
       
    },[])

    const onObjectiveClicked=(e, objective)=>{
        let criteria ={objective_id:objective.id};
        let path="";
        switch(objective.title){
            case "ACCUPUNCTURE":
                path="AcupunctureSessionRecords";
                break;
            case "DIET COUNSELLING":
                path="DietitianSessionRecords";
                break;
            case "REIKI":
                path="GenericFinancialOutcomeRecords";
                break;
            default :
                path="Blank";
                break;
        }
        navigate({pathname :path,search: createSearchParams(criteria).toString()});
        e.preventDefault();
    }
    return (
        <div>
            <Box sx={{mt:2, mx:'auto',height: 75,width:800}}>
                <Typography variant="h6">Custom Analysis</Typography>
                <Grid container>
                    {
                        objectives.map((objective,i)=>{
                            return (
                            <Grid item key={i}>
                                <Chip
                                sx={{m:1}}
                                icon={<CrisisAlert />}
                                label={objective.title}
                                variant="outlined"
                                size="small"
                                onClick={(e)=>{onObjectiveClicked(e,objective)}}
                                />
                            </Grid>
                            );
                        })
                    }
                </Grid>
                <Outlet />
            </Box>
            
        </div>
    );
}

export default ObjectiveAnalysis;