import { Card, CardContent, CardHeader,Box,Grid,FormControl,Input,InputAdornment,TextField,Button,Select,MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getICon } from '../API/AppIconService';
import TagService from '../API/TagService';
import ContactService from '../API/ContactService';
import { useNavigate } from 'react-router-dom';

function ContactsDetail(props) {
    const [myCategories,setMyCategories] = useState([]);
    const [name,setName]=useState('');
    const [company,setCompany]=useState('');
    const [designation,setDesignation] = useState('');
    const [email,setEmail]=useState('');
    const [phone,setPhone]=useState('');
    const [tagname,setTagname] = useState('');
    const [categoryname,setCategoryName] = useState('');
    const [tagid,setTagID] = useState(-1);
    const [url,setUrl]=useState('');
    const [street,setStreet] = useState('');
    const [area,setArea] = useState('');
    const [city,setCity] = useState('');
    const [cstate,setCState] = useState('');
    const [pincode,setPincode] = useState('');
    const [notes,setNotes] = useState('');
    const [introducedBy,setIntroducedBy] = useState('');
    
    const navigate = useNavigate();

    const save = (e)=>{
        var details = {name:name,company:company,designation:designation,email:email,phone:phone,tagname:tagname,categoryname:categoryname,tagid:tagid,url:url,street:street,area:area,city:city,state:cstate,pincode:pincode,notes:notes,introducedBy:introducedBy};
        ContactService.addContact(details);
    }

    useEffect(()=>{
        TagService.listCatTagsForUser().then((response)=>{
            setMyCategories(response.data);
        })
    },[])

    return (
        <Box sx={{mt:2, mx:'auto',height: 75,width:800}}>
        <Card>
            <CardHeader title={"New Contact"} />
            <CardContent>
                <Typography>Personal Details</Typography>
                <Grid  container columnSpacing={1} rowGap={0}>
                    
                    <Grid height={50} item>
                        <TextField margin="normal" required fullWidth label="Name" value={name} onChange={(e)=>{setName(e.target.value)}} variant='standard' autoFocus />
                    </Grid>
                    <Grid  height={50} item>
                        <TextField margin="normal" required fullWidth label="Phone Number" value={phone} onChange={(e)=>{setPhone(e.target.value)}}  variant='standard' autoFocus />
                    </Grid>
                    <Grid  height={50} item>
                        <TextField margin="normal" required fullWidth label="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} type='email'  variant='standard' autoFocus />
                    </Grid>
                    <Grid  height={50} item>
                        <TextField margin="normal" fullWidth label="Company"  value={company} onChange={(e)=>{setCompany(e.target.value)}}  variant='standard' autoFocus />
                    </Grid>
                    <Grid height={50} item>
                        <TextField margin="normal" fullWidth label="Designation"  value={designation} onChange={(e)=>{setDesignation(e.target.value)}} variant='standard' autoFocus />
                    </Grid>
                    <Grid height={50} item>
                        <TextField margin="normal" fullWidth label="URL"  value={url} onChange={(e)=>{setUrl(e.target.value)}} variant='standard' autoFocus />
                    </Grid>
                </Grid>
                <Typography sx={{mt:3}}>Address</Typography>
                <Grid  container columnSpacing={1} rowGap={0}>
                    <Grid height={50} item>
                        <TextField margin="normal" fullWidth label="Street" value={street} onChange={(e)=>{setStreet(e.target.value)}} variant='standard'  autoFocus />
                    </Grid>
                    <Grid height={50} item>
                        <TextField margin="normal" fullWidth label="Area" value={area} onChange={(e)=>{setArea(e.target.value)}} variant='standard' multiline maxRows={4} autoFocus />
                    </Grid>
                    <Grid height={50} item>
                        <TextField margin="normal" fullWidth label="City" value={city} onChange={(e)=>{setCity(e.target.value)}} variant='standard' multiline maxRows={4} autoFocus />
                    </Grid>
                    <Grid height={50} item>
                        <TextField margin="normal" fullWidth label="State" value={cstate} onChange={(e)=>{setCState(e.target.value)}} variant='standard' multiline maxRows={4} autoFocus />
                    </Grid>
                    <Grid height={50} item>
                        <TextField margin="normal" fullWidth label="Pincode" value={pincode} onChange={(e)=>{setPincode(e.target.value)}} variant='standard' multiline maxRows={4} autoFocus />
                    </Grid>
                </Grid>
                <Grid  container columnSpacing={1} rowGap={0}>
                    <Grid height={50} item>
                        <TextField margin="normal" multiline maxRows={4} value={notes} onChange={(e)=>{setNotes(e.target.value)}} fullWidth label="Notes" variant='standard' autoFocus />
                    </Grid>
                    <Grid container spacing={3} sx={9}>
                        <Grid item>
                            <TextField margin="normal" fullWidth value={introducedBy} onChange={(e)=>{setIntroducedBy(e.target.value)}} label="Introduced by / Met @ " variant='standard' autoFocus />
                        </Grid>
                        <Grid item gutterBottom>
                            <FormControl sx={{mt:3,width:100}} >
                                <Select variant="standard" value={tagid} label="Tag" onChange={(e)=>{setTagID(e.target.value)}}>
                                    <MenuItem value={-1}>
                                         
                                    </MenuItem>
                                    {
                                        myCategories.map((cat) => {
                                            return (
                                                <MenuItem value={cat.id}>
                                                   {cat.tagname} {getICon(cat.categoryname)}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        
                    </Grid>
                    
                </Grid>
                <Button onClick={(e)=>{save()}} variant="contained" sx={{mr:1}}>Save</Button>
            </CardContent>
        </Card>
        </Box>
    );
}

export default ContactsDetail;