import React, { useState,useEffect } from 'react';
import { Grid,ButtonGroup,IconButton,TextField,Dialog,DialogTitle,DialogContent,DialogActions,Button } from '@mui/material';
import TagService from '../API/TagService';
import { getCategoryIcon } from './Apptag';
import CreateIcon from '@mui/icons-material/Create';

function ApptagDescription(props) {
    const [curCategory,setCurCategory] = useState({});
    const [openDlg,setOpenDlg]=useState(false);
    const [newDescription,setNewDescription]= useState('');
    const [categoryList,setCategoryList] = useState([]);
    
    useEffect(()=>{
        TagService.listCategoriesForUser().then((response)=>{
            setCategoryList(response.data);
        });
    },[])

    const setDescription=(cat)=>{
        setCurCategory(cat);
        setNewDescription(cat.description);
        setOpenDlg(true);
    }
    
    const handleClickOpen = () => {
        setOpenDlg(true);
    };

    const handleClose = () => {
        setOpenDlg(false);
    };

    const handleSave = () =>{
        TagService.updateCategoryDiscription(curCategory.id,newDescription );
        setOpenDlg(false);
        setNewDescription("");
    }

    return (
        <>
            <Grid container >
                {
                    categoryList.map((cat,i)=>{
                        return (
                            <Grid item>
                            <ButtonGroup>
                                <IconButton>
                                    { getCategoryIcon(cat.category)}
                                </IconButton>
                                <TextField variant='standard' value={cat.description} 
                                label="Description" size='small'
                                inputProps={{maxlength:20}}
                                />
                                <IconButton  onClick={(e)=>{setDescription(cat)}}>
                                    <CreateIcon />
                                </IconButton>
                            </ButtonGroup>
                        </Grid> 
                        )
                    })

                }   
            </Grid>
            <Dialog open={openDlg} onClose={handleClose}>
                <DialogContent>
                    <TextField
                        autoFocus
                        variant='standard'
                        margin="dense"
                        size='small'
                        label="new description" 
                        onChange={(e)=>{setNewDescription(e.target.value)}} 
                        value={newDescription}                      
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ApptagDescription;