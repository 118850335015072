import axios from "./DBService";

const ContactService = {
    listMyContacts:(userID)=>{
       // return contacts;
        return axios.get("/contact");
    },
    listMyContactsByPhoneName:(phoneno="",name="")=>{
        let data ={name:name,phoneno:phoneno};
        return axios.post("/contactsbyphonename",data);
    },
    listMyContactsByTagCat:(tagname="",categoryname="")=>{
       let data = {"tagname": tagname,"categoryname": categoryname};
       return axios.post("/listContactsByTagCat",data);
    },
    addContact:(contactDetail)=>{
       // {tagname:tagname,categoryname:categoryname};
        let data={"name":contactDetail.name,"company":contactDetail.company,"designation":contactDetail.designation,"mobile":contactDetail.phone,'mobile1':"",'email':contactDetail.email,'url':contactDetail.url,'street':contactDetail.street,'area':contactDetail.area,'city':contactDetail.city,'state': contactDetail.state ,'pincode':contactDetail.pincode,'notes':contactDetail.notes,'introducedby':contactDetail.introducedBy,tagid:contactDetail.tagid};
        return axios.post("/createcontactwithtag",data);
    },
    addTagForMyContact:(ContactPhone,tagid)=>{
        let data = {"contactphone":ContactPhone,"tagid":tagid};
        return axios.post("/assignTagForContact",data);
    },
    listTagsForMyContact:(ContactPhoneNo)=>{
        let data={"mobile":ContactPhoneNo};
        return axios.post("/listTagsForContact",data);
    },
    listVirginContacts:()=>{
        return axios.post("/tag/listVirginContacts");
    }
}

export default ContactService;