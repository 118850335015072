import React,{useEffect, useState} from 'react';
import {Table,TableBody, TableContainer,TableHead,TableRow,TableCell,TablePagination, Typography,Box, IconButton, ButtonGroup } from '@mui/material';
import {Paper} from '@mui/material';
import {getCategoryIcon} from '../Utils/Apptag'
import {EventNote,Style} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ContactService from '../API/ContactService';
import { useSearchParams } from 'react-router-dom';
import axios from '../API/DBService'


function ContactSearchResult(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [contacts,setContacts]=useState([]);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const gotoFollowups=(row)=>{
        navigate("../../followup",{state:{record:row}});
    }

    const gotoTags=(row)=>{
        navigate("../../tag",{state:{record:row}});
    }

    useEffect(()=>{
        const search = {categoryname:searchParams.get("categoryname"),tagname:searchParams.get("tagname"),name:searchParams.get("name"),phoneno:searchParams.get("phoneno")};
        
        var initData = [{
            "name": "",
            "company": "",
            "designation": "",
            "email": "",
            "phone": "",
            "url": "",
            "address": "",
            "notes": "",
            "introducedBy": ""
        }];
        //setContacts(initData);

        if(!(search.categoryname === null) && search.tagname===null){
            //setContacts(initData);
            ContactService.listMyContactsByTagCat("",search.categoryname).then((response)=>{
                //alert("searching for category name " +search.categoryname);
                setContacts(response.data);
            })
        }
        else if(search.categoryname === null && !(search.tagname===null)){
            //alert("searching for tag name " + search.tagname);
            //setContacts(initData);
            ContactService.listMyContactsByTagCat(search.tagname,"").then((response)=>{
                setContacts(response.data);
            })
        }
        else if(!(search.categoryname === null) && !(search.tagname===null)){
            //setContacts(initData);
            //alert("searching for category and tag name " + search.categoryname + " : " + search.tagname);
            ContactService.listMyContactsByTagCat(search.tagname,search.categoryname).then((response)=>{
                setContacts(response.data);
            })
        }
        if(!(search.name == "") && search.phoneno == "" ){
            ContactService. listMyContactsByPhoneName("",search.name).then((response)=>{
                setContacts(response.data);
                
            })
        }
        else if(!(search.phoneno == "") && search.name == ""){
            ContactService.listMyContactsByPhoneName(search.phoneno,"").then((response)=>{
                setContacts(response.data);
              
            })
        }
        else{
            ContactService.listMyContacts(123).then((response)=>{
                setContacts(response.data);
            })
        }
    },[searchParams])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" sx={{width:"100%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width:"30%"}}>Name</TableCell>
                            <TableCell sx={{width:"30%"}}>Company</TableCell>
                            <TableCell sx={{width:"10%"}}>Phone</TableCell>
                            <TableCell sx={{width:"10%"}}>Email</TableCell>
                            <TableCell sx={{width:"10%"}}>Tag</TableCell>
                            <TableCell sx={{width:"10%"}}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            typeof contacts!== 'undefined' ?
                            contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((contact,i) => {
                                return (
                                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                                    <TableCell padding='none' >{contact.name}</TableCell>
                                    <TableCell padding='none' >{contact.company}</TableCell>
                                    <TableCell padding='none' >{contact.mobile}</TableCell>
                                    <TableCell padding='none' ><Typography sx={{ml:1}}>{contact.email}</Typography> </TableCell>
                                    <TableCell padding='none' >{getCategoryIcon(contact.categoryname)} {contact.tagname}</TableCell>
                                    <TableCell padding='none' align="left">
                                        <ButtonGroup>
                                            <IconButton onClick={(e)=>{gotoFollowups(contact)}} color='primary'>
                                                <EventNote />
                                            </IconButton>
                                            <IconButton onClick={(e)=>{gotoTags(contact)}} color='primary'>
                                                <Style />
                                            </IconButton>
                                        </ButtonGroup>
                                    </TableCell>
        
                                </TableRow>
                                );
                            }) 
                            :
                            null
                        }
                    
                    </TableBody>
                </Table>
                {
                    typeof contacts!== 'undefined' ?
                    <TablePagination
                        rowsPerPageOptions={[2,3,10,15,20]}
                        component="div"
                        count={contacts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    :
                    null
                }
            </TableContainer>
        </Paper>
    );
}

export default ContactSearchResult;