import React from 'react';
import {Outlet,useNavigate} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {AppBar,Toolbar,Typography,BottomNavigation,BottomNavigationAction,Box,IconButton,Grid }from '@mui/material';
import Paper from '@mui/material/Paper';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Add,LocalOffer,Home,Palette} from '@mui/icons-material';

function Objectives(props) {
    const navigate = useNavigate();
    return (
        <>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                    <CrisisAlertIcon sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit">
                        Objective
                    </Typography>
                    <Grid container
                        direction="row"
                        justifyContent="end"
                        alignItems="center">
                        
                            <IconButton onClick={(e)=>{navigate("create")}}>
                                <Add sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("analysis")}} >
                                <AssessmentIcon  sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("../objective")}} >
                                <Palette  sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("/home")}}>
                                <Home    sx={{color:'white'}}/>
                            </IconButton>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Outlet /> 
        </>
    );
}

export default Objectives;