
import { Typography,Box, Button,TextField,Grid, IconButton, Stack,LinearProgress,FormControl,Select,MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import { getICon } from '../API/AppIconService';
import { AutoFixHigh } from '@mui/icons-material';
import TagService from '../API/TagService';
import ContactService from '../API/ContactService';

const imageMimeType = /image\/(png|jpg|jpeg)/i;

function ContactOCR(props) {
    const [myCategories,setMyCategories] = useState([]);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = React.useState(0);
    const [progStatus,setProgStatus] = React.useState('Status');
    const [extData,setExtData]=useState([]);

    const [name,setName]=useState('');
    const [company,setCompany] = useState('');
    const [mobile,setMobile] = useState('');
    const [email,setEmail]= useState('');
    const [street,setStreet] = useState('');
    const [area,setArea] = useState('');
    const [city,setCity] = useState('');
    const [cstate,setCState] = useState('');
    const [pincode,setPincode] = useState('');
    const [designation,setDesignation] = useState('');
    const [url,setURL] = useState('');
    const [notes,setNotes] = useState('');
    const [introducedBy,setIntroducedBy] = useState('');
    const [tagid,setTagID] = useState(-1);


    const [processingIndicator,setProcessingIndicator] = useState(false);

    const onFileSelect = (e)=>{
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
          alert("Image mime type is not valid");
          return;
        }
        setFile(file);
        resetVariables();
    }

    const processData = (data=[])=>{
        let retLines=[];
        let counter=1;
        data.forEach(element => {
            retLines.push({text:element.text,words:element.words,id:counter});
            counter = counter +1;
        });
        return retLines;
    }

    const cleanLine = (lineData,idx) =>{
        setExtData(extData.map((data,i)=>{
            if(data.id == lineData.id){
                return {...extData,text:applyMagicWand(lineData)}
            }else{
                return data
            }
        }));

    }

    const applyMagicWand = (lineData)=>{
        let line="";
        lineData.words.forEach(word=>{
            if(word.confidence > 60){
                if(line!=""){
                    line = line + " " + word.text;
                }else{
                    line = word.text;
                }
            }
        })
        return line;
    }
    const extractInfo = (e)=>{
        resetVariables()
        const corePath = window.navigator.userAgent.indexOf("Edge") > -1
        ? '../js/tesseract-core.asm.js'
        : '../js/tesseract-core.wasm.js';
        const worker = new Tesseract.TesseractWorker({corePath}); // eslint-disable-line
        worker.recognize(file,"eng")
            .progress(function(packet){
                setProgStatus(packet.status);
                if(packet.status == 'recognizing text'){
                    setProgress(progress+packet.progress);
                }
                
            })
            .then(function(data){
                setProgStatus("completed");
                setProgress(100);
                setProcessingIndicator(false);
                let lines=[];
                lines = processData(data.lines);
                setExtData(lines);
                console.log(lines);
            })
    }

    const save = (e)=>{
        var details = {name:name,company:company,designation:designation,email:email,phone:mobile,tagid:tagid,url:url,street:street,area:area,city:city,state:cstate,pincode:pincode,notes:notes,introducedBy:introducedBy};
        ContactService.addContact(details);
        resetVariables();
    }
    const resetVariables= ()=>{
        setExtData([]);
        setProgress(0);
        setProgStatus('Status');
        setProcessingIndicator(false);
        setName('');
        setMobile('');
        setEmail('');
        setCompany('');
        setDesignation('');
        setStreet('');
        setArea('');
        setCity('');
        setCState('');
        setPincode('');
        setURL('');
        setNotes('');
        setIntroducedBy('');
        setTagID(-1);
    }
    
    useEffect(()=>{
        let fileReader, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    },[file])

    useEffect(()=>{
        TagService.listCatTagsForUser(123).then((response)=>{
            setMyCategories(response.data);
        })
    },[])


    return (
        <div>
            <Box sx={{mt:2, mx:'auto',maxHeight: 75,maxWidth:800}}>
                <Typography sx={{mb:2}}>Upload Visiting Card photo for scan</Typography>
                <TextField variant='standard' type={'file'} accept='.png, .jpg, .jpeg' helperText='Select image file to upload' onChange={(e)=>{onFileSelect(e)}}></TextField>
                <Grid container direction="column" alignItems="center">
                    {
                        fileDataURL? 
                        <>
                        <Grid item> 
                            <Box component={'img'} src={fileDataURL} sx={{maxHeight: { xs: 433, md: 433 },
                        maxWidth: { xs: 288, md: 288 } }} >
                            </Box> 
                        </Grid>
                        <Grid item >
                            <IconButton disabled={processingIndicator} onClick={(e)=>{extractInfo(e)}} sx={{width:98,height:98,fontSize:56}}>
                                <ModelTrainingIcon color={processingIndicator?'secondary':'primary'} fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        
                        <Stack>
                            {
                                progress <100 ? <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                    <LinearProgress variant="determinate" value={progress} />
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                    <Typography variant="body2" color="text.secondary">{progress}</Typography>
                                </Box>
                                </Box>
                                <Typography variant="body2" color="text.secondary">{progStatus}</Typography> </> : null
                            }
                            
                            {
    
                              extData.map( (entry,i)=>{
                                    return <Grid component={'span'}>{entry.text}<IconButton size='small' onClick={(e)=>{cleanLine(entry,i)}}><AutoFixHigh /></IconButton></Grid>
                                })
                            }
                            {
                               progress == 100 ? <>
                                <Typography variant='h6'>Contact details</Typography>
                                <TextField variant='standard' size={'small'} value={name} onChange={(e)=>{setName(e.target.value)}} helperText='Name'></TextField>
                                <TextField variant='standard' size={'small'}  value={mobile} onChange={(e)=>{setMobile(e.target.value)}} helperText='Mobile'></TextField>
                                <TextField variant='standard' value={email} onChange={(e)=>{setEmail(e.target.value)}} helperText='Email'></TextField>
                                <TextField variant='standard' value={company} onChange={(e)=>{setCompany(e.target.value)}} helperText='Company Name'></TextField>
                                <TextField variant='standard' value={designation} onChange={(e)=>{setDesignation(e.target.value)}} helperText='Designation'></TextField>
                                
                                <TextField variant='standard' value={street} onChange={(e)=>{setStreet(e.target.value)}} helperText='Street'></TextField>
                                <TextField variant='standard' value={area} onChange={(e)=>{setArea(e.target.value)}} helperText='Area'></TextField>
                                <TextField variant='standard' value={city} onChange={(e)=>{setCity(e.target.value)}} helperText='City'></TextField>
                                <TextField variant='standard' value={cstate} onChange={(e)=>{setCState(e.target.value)}} helperText='State'></TextField>
                                <TextField variant='standard' value={pincode} onChange={(e)=>{setPincode(e.target.value)}} helperText='Pincode'></TextField>
                                <TextField variant='standard' value={url} onChange={(e)=>{setURL(e.target.value)}} helperText='URL'></TextField>
                                <TextField variant='standard' value={notes} onChange={(e)=>{setNotes(e.target.value)}} helperText='Notes'></TextField>
                                <TextField variant='standard' value={introducedBy} onChange={(e)=>{setIntroducedBy(e.target.value)}} helperText='Introduced By'></TextField>
                                <FormControl sx={{mt:3,width:100}} >
                                    <Select variant="standard" value={tagid} label="Tag" onChange={(e)=>{setTagID(e.target.value)}}>
                                        <MenuItem value={-1}></MenuItem>
                                        {
                                            typeof myCategories!== 'undefined' ?
                                            myCategories.map((cat) => {
                                                return (
                                                    <MenuItem value={cat.id}>
                                                    {cat.tagname} {getICon(cat.categoryname)}
                                                    </MenuItem>
                                                )
                                            }) : null
                                        }
                                    </Select>
                                </FormControl>
                                <Button onClick={(e)=>save()} variant='text' sx={{m:-1}}>Save </Button>
                               </> : null
                            }
                            
                        </Stack>
                        </> : null
                    }
                </Grid>
            </Box>
        </div>
    );
}

export default ContactOCR;