import axios from '../API/DBService';

const ObjectiveService = {
    ServiceName:()=>{
        console.log("hello")
    },
    listObjectivesForUser: ()=>{
        return axios.get("/objective");
    },
    addObjectiveForUser:(objective)=>{
        let data = {"title":objective.title,"description":objective.description};
        return axios.post("/objective",data);
    },
    listOutcomeForObjectives:(objectiveid,fromDate,toDate)=>{
        let data = {objectiveid:objectiveid,fromDate:fromDate,toDate:toDate};
        return axios.post("/objective/listOutcomes",data);
    }
}

export default ObjectiveService;