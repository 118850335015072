
import {Gavel,CurrencyRupee,Favorite,LockOpen,QuestionMark, Event, EventAvailable, EventRepeat, EventBusy,Payment,CakeSharp,Call,WhatsApp,Groups,Vaccines,LocalLibrary,Badge,Loyalty,Label,PushPin,Handshake,Place,LocalDining,LocalCafe} from '@mui/icons-material';


export const getICon =(iconName,color='primary')=>{
    switch (iconName) {
        case "Gavel":
            return <Gavel color={color} />
        case "CurrencyRupee":
            return <CurrencyRupee color={color} />        
        case "Favorite":
            return <Favorite color={color} /> 
        case "LockOpen":
            return <LockOpen color={color} />
        case "QuestionMark":
            return <QuestionMark color={color} />
        case "Event":
            return <Event color={color} />        
        case "EventAvailable":
            return <EventAvailable color={color} /> 
        case "EventRepeat":
            return <EventRepeat color={color} />
        case "EventBusy":
            return <EventBusy color={color} />
        //following are category icons
        case "CakeSharp":
            return <CakeSharp color={color}/>
        case "Payment":
            return <Payment color={color} />
        case "CurrencyRupee":
            return <CurrencyRupee color={color} />
        case "Call":
            return <Call color={color} />
        case "WhatsApp":
            return <WhatsApp color={color}/>
        case "Groups":
            return <Groups color={color}/>
        case "Vaccines":
            return <Vaccines color={color}/>
        case "LocalLibrary":
            return <LocalLibrary color={color}/>
        case "Badge":
            return <Badge color={color}/>  
        case "Loyalty":
            return <Loyalty color={color}/> 
        case "Label":
            return <Label color={color}/> 
        case "PushPin":
            return <PushPin color={color}/> 
        case "Handshake":
            return <Handshake color={color}/> 
        case "Place":
            return <Place color={color}/>
        case "LocalDining":
            return <LocalDining color={color}/> 
        case "LocalCafe":
            return <LocalCafe color={color}/> 
        
        default:
            break;
    }
}