import { TextField,IconButton,Button, ButtonGroup, getIconButtonUtilityClass } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import {getICon} from '../API/AppIconService';
import TagService from '../API/TagService';

export function getCategoryIcon(categoryName,color='primary'){
    return getICon(categoryName,color);
}

function Apptag({onSelection,AutoCreate=true}) {
    const [tagname,setTagname] = useState('');
    const [selectedCategory, setSelectedCategory] = React.useState(''); 
    const [categoryList,setCategoryList] = useState([]);

    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const selectedEvent = (event)=>{
        onSelection(selectedCategory,tagname,"PUBLIC");
        setTagname("");
        setSelectedCategory("");
    }

    const ClearEvent = (event)=>{
        setTagname("");
        setSelectedCategory("");
    }

    useEffect(()=>{
        TagService.listCategoriesForUser().then((response)=>{
            setCategoryList(response.data);
        });
    },[])

    return (
     <>
        <Grid container direction={"column"}>
            <Grid container>
                <Grid item>
                    <ButtonGroup>
                        <TextField variant='standard' value={tagname} 
                        onChange={(e)=>{setTagname(e.target.value)}}
                        label="Tag Name"
                        size='small'
                        helperText="Type tag name and select category from the following list"
                        inputProps={{maxLength:20}}
                        />
                        <IconButton>
                            { getCategoryIcon(selectedCategory)
                            }
                        </IconButton>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button variant={"contained"} sx={{margin:1}} size="small" onClick={selectedEvent}>Select</Button>
                        <Button variant={"contained"} sx={{margin:1}} size="small" onClick={ClearEvent}>Clear</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Grid container>
                {
                    categoryList.map((cat,i)=>{
                        return(
                            <Radio key={i} icon={getICon(cat.category,"primary")} checked={selectedCategory === cat.category}
                                onChange={handleChange}
                                value={cat.category}
                                name="radio-buttons">
                            </Radio>
                        )
                    })
                }
            </Grid>
        </Grid>
    </>  
    );
}

export default Apptag;