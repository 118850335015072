import React,{useState} from 'react';
import { TextField, Typography } from '@mui/material';

function DietitianSessionOutcome({onDataCollection,Height}) {
    const [weight,setWeight] = useState(0);
    const [bmi,setBmi]= useState(0);

    const updateParent =(e)=>{
        let data = {height:Height,weight:weight,bmi:bmi};
        onDataCollection(data);
        e.preventDefault();
    }

    const update=(e)=>{
        setWeight(e.target.value);
        var denominator = Height * Height;
        var bmi = e.target.value / denominator;
        setBmi(bmi);
        updateParent(e);
    }
    return (
        <>
            <Typography variant='subtitle2' > BMI : {bmi} </Typography>
            <TextField autoFocus variant='standard' margin="dense" size='small' type={'number'} label="Weight" 
                onChange={(e)=>{update(e)}} 
                value={weight}                      
            />
        </>
    );
}

export default DietitianSessionOutcome;