import React from 'react';

function DietitianSessionRecords(props) {
    return (
        <div>
            DietitianSessionRecords
        </div>
    );
}

export default DietitianSessionRecords;