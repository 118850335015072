import React, { useEffect,useState } from 'react';
import {ArrowBack,Save,AutoFixNormal} from '@mui/icons-material';
import { Box, IconButton, Typography,Paper,TableContainer,Table,TableHead,TableBody,TableRow,TableCell,TablePagination,Grid, FormControl, TextField,  ButtonGroup,MenuItem,Dialog,Select,Popover} from '@mui/material';

import { useLocation,useNavigate } from 'react-router-dom';
import {getEventStatusIcon} from '../Utils/AppOptions';
import ReminderService from '../API/ReminderService';
import ObjectiveService from '../API/ObjectiveService';
import DlgUpdateFollowupStatus from '../Followups/DlgUpdateFollowupStatus';


const initContactDetails={name:"Subramani",company:"total-sols",designation:"Founder",email:"as@totalsols.in",phone:"9845068263",tagname:"VBF",categoryname:"Groups"};


function ContactFollowups(props) {
    const loc = useLocation();
    const navigate = useNavigate();
    const [contactDetails,setContactDetails]=useState(initContactDetails);
    const [reminders,setReminders] = useState([]);
    const [myobjectives,setMyobjectives] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);

    const [objective,setObjective]= useState('');
    const [followupDate,setFollowupDate]=useState('');
    const [note,setNote]=useState('');
    
    //dialog box variables
    const [openDlg,setOpenDlg]=useState(false);
    const [creminder,setCReminder] = useState({});
    const [height,setHeight] = useState(1.6)

    const [cidx,setCidx]=useState(-1);
    const [outcome,setOutcome]=useState('');
    const [selectedStatus,setSelectedStatus]=useState("");
    //popup state variables
    const [anchorEl, setAnchorEl]=useState(null);
    const [openPopup,setOpenPopup]=useState(false);
    const [iconButtonID,setIconButtonID]= useState(undefined);
    const [outcomeText,setOutcomeText]= useState('');

    useEffect(()=>{
        setContactDetails(loc.state.record);
        
        ObjectiveService.listObjectivesForUser().then((response)=>{
            setMyobjectives(response.data);
        });
        refresh();
    },[])

    const refresh = ()=>{
        ReminderService.ListMyRemindersForContact(loc.state.record.id).then((response)=>{
            setReminders(response.data);
            console.log(response.data);
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleObjectiveChange = (event) =>{
        setObjective(event.target.value);
    }

    const save = (event)=>{
        let data = {"contact_id":contactDetails.id,
            "followupdate":followupDate.replace("T", " "),
            "note":note,
            "status":"SCHEDULED",
            "objective_id": objective};
        
        ReminderService.addMyReminderForContact(data).then((response)=>{
            clear();
            refresh();
        });
    }

    const clear = ()=>{
        setNote("");
        setFollowupDate("");
        setObjective("");
    }
    
    const updateReminderStatus = (event,reminder,idx) =>{
        setCReminder(reminder);
        setOutcome('');
        setSelectedStatus('');
        setOpenDlg(true);
        setCidx(idx);
        event.preventDefault();
    }

    const handleDlgClose = (event) =>{
        setOpenDlg(false);
    }

    const updateFollowupStatus = (response)=>{
        //response = {cmd:"save",status:selectedStatus,note:outcome,data:matric}
        if(response.cmd=="save"){
            console.log(" contact_id " + contactDetails.id);
            console.log("status " + response.status);
            console.log("note " + response.note);
            console.log("data " + JSON.stringify(response.data));
            console.log("remider id " + creminder.id);
            ReminderService.updateMyReminderStatusForContact(contactDetails.id,response.status,response.note,JSON.stringify(response.data),creminder.id);
        }
        setOpenDlg(false);
    }

    //Popup related methods
    const handleShowOutcomeClick = (event,buttonID,outcome) => {
        setAnchorEl(event.currentTarget);
        setIconButtonID(buttonID);
        ReminderService.getOutcomeForReminder(outcome).then((response)=>{
            setOutcomeText("Outcome : " + response.data.note);
        })
        setOpenPopup(true);
    };
    const handleShowOutcomeClose = () => {
        setOpenPopup(false);
        setAnchorEl(null);
        setIconButtonID(undefined);
        setOutcomeText("");
    };

    return (
        <>
            <Box sx={{mt:2, mx:'auto',height: 75,width:800 }}>
                <Grid container justifyContent="space-between" sx={{mb:2}}>
                    <Grid>
                        <IconButton onClick={()=>navigate(-1)}><ArrowBack /></IconButton>
                        <Typography variant='h6'>Followups with {contactDetails.name}</Typography>
                    </Grid>
                    <Grid>
                        <Typography variant='subtitle1'>{contactDetails.company}</Typography>
                        <Typography variant='subtitle2'>{contactDetails.designation}</Typography>
                        <Typography variant='subtitle2'>{contactDetails.phone}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Typography sx={{"text-decoration": "underline solid  10%"}} >New Followup</Typography>
                    <Grid container alignItems="flex-end">  
                        <Grid xs={3} sx={{mr:2,mt:2}}>
                            <Select value={objective} variant="standard"
                                label="Objective"
                                size={'small'}
                                fullWidth
                                onChange={handleObjectiveChange} >
                                <MenuItem key={0} value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    myobjectives.map((objective,i) => {
                                        return (
                                            <MenuItem key={i}  value={objective.id}>
                                                {objective.title}
                                            </MenuItem> 
                                        );
                                    })
                                }
                            </Select>
                        </Grid>
                        <Grid xs={3} sx={{mr:2,mt:2}}>
                            <TextField variant='standard' type={'datetime-local'}  
                            value={followupDate} onChange={(e)=>{setFollowupDate(e.target.value)}}
                            autoFocus></TextField>
                        </Grid>
                        <Grid xs={3} sx={{mr:2,mt:2}}>
                            <TextField variant='standard' type={'text'}
                             label="Note" value={note} onChange={(e)=>{setNote(e.target.value)}}
                            autoFocus></TextField>
                        </Grid>
                        <Grid xs={3} sx={{mr:2,mt:2}}>
                            <ButtonGroup variant='contained'>
                                <IconButton color='primary' onClick={(e)=>{save(e)}} sx={{mr:2, mb:1}}><Save /> </IconButton>
                                
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid sx={{mr:2,mt:2}}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Typography sx={{"text-decoration": "underline solid  10%"}} >History </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >Date</TableCell>
                                            <TableCell >Note</TableCell>
                                            <TableCell >Status</TableCell>
                                            <TableCell >Objective</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {reminders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((reminder,i) => {
                                            return(
                                                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell >{reminder.followupdate} </TableCell>
                                                    <TableCell >{reminder.note} </TableCell>
                                                    <TableCell >
                                                        {
                                                         (reminder.status == "SCHEDULED") ?
                                                            <IconButton onClick={(e)=>{updateReminderStatus(e,reminder,i)}}>
                                                                    {getEventStatusIcon(reminder.status)}
                                                                    </IconButton>
                                                            : 
                                                            <Box>
                                                                <IconButton aria-describedby={'popover'+reminder.reminderid} size='small' onClick={(e)=>{handleShowOutcomeClick(e,'popover'+reminder.reminderid,reminder.outcome_id)}} >
                                                                    {getEventStatusIcon(reminder.status,"secondary")}
                                                                </IconButton>
                                                            </Box>
                                                                
                                                        }
                                                         
                                                    </TableCell>
                                                    <TableCell >{reminder.title} </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[1,3,10,15,20]}
                                    component="div"
                                    count={reminders.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Box> 
            <Popover id={iconButtonID} open={openPopup} anchorEl={anchorEl}
                onClose={handleShowOutcomeClose} anchorOrigin={{ vertical: 'bottom',horizontal: 'left'}} >
                <Typography sx={{ p: 2 }}>{outcomeText}</Typography>
            </Popover>
            <Dialog open={openDlg} onClose={handleDlgClose}>
                <DlgUpdateFollowupStatus onSelection={updateFollowupStatus} objectiveID={creminder.title} inputData={height} />
            </Dialog>
            
        </>
    );
}

export default ContactFollowups;