import axios from '../API/DBService';

var ContactReminders=[
    {reminderid:"1",contact:"Arun Rao",company:"Nodu",contactid:"2",contactphone:"7406262728" ,followupdate:"2022-10-02 09:00:00","note":"Arrange meeting","status":"SCHEDULED","outcome":{note:"","income":"",expense:""},"objective":"NEWYEAR CAMPAIGN","ObjectiveID":1},
    {reminderid:"2",contact:"Subramani",company:"total-sols",contactid:"3",contactphone:"9845068263" ,followupdate:"2022-10-02 09:00:00","note":"Demo","status":"EXECUTED","outcome":{note:"excellent","income":"500",expense:"800"},"objective":"PRODUCT LAUNCH","ObjectiveID":2},
    {reminderid:"3",contact:"Keshavan",company:"Automation and Energy Solutions",contactid:"4",contactphone:"9019924922" ,followupdate:"2022-10-02 09:00:00","note":" It will also need a function to set the state to a color, wait for a second, and then set it back to the default value.","status":"DIFFERED","outcome":{note:"",income:"",expense:""},"objective":"PRODUCT LAUNCH","ObjectiveID":3},
    {reminderid:"4",contact:"Dr.P.Ramakanthkumar",company:"RV College of Engineering",contactid:"1",contactphone:"9886309520" ,followupdate:"2022-10-02 09:00:00","note":"Compliance meeting","status":"CANCELLED","outcome":{note:"","income":"",expense:""},"objective":"LIFE INSURANCE","ObjectiveID":4},
    {reminderid:"5",contact:"Arun Rao",company:"Nodu",contactid:"2",contactphone:"7406262728" ,followupdate:"2022-10-02 09:00:00","note":"Arrange meeting","status":"SCHEDULED","outcome":{note:"","income":"",expense:""},"objective":"LIFE INSURANCE","ObjectiveID":1},
    {reminderid:"6",contact:"Subramani",company:"total-sols",contactid:"3",contactphone:"9845068263" ,followupdate:"2022-10-02 09:00:00","note":"Demo","status":"EXECUTED","outcome":{note:"","income":"",expense:""},"objective":"NEWYEAR CAMPAIGN","ObjectiveID":2},
    {reminderid:"7",contact:"Keshavan",company:"Automation and Energy Solutions",contactid:"4",contactphone:"9019924922" ,followupdate:"2022-10-02 09:00:00","note":" It will also need a function to set the state to a color, wait for a second, and then set it back to the default value.","status":"DIFFERED","outcome":{note:"",income:"",expense:""},"objective":"PRODUCT LAUNCH","ObjectiveID":3},
    {reminderid:"8",contact:"Dr.P.Ramakanthkumar",company:"RV College of Engineering",contactid:"1",contactphone:"9886309520" ,followupdate:"2022-10-02 09:00:00","note":"Compliance meeting","status":"CANCELLED","outcome":{note:"","income":"",expense:""},"objective":"PRODUCT LAUNCH","ObjectiveID":4},
    {reminderid:"9",contact:"Arun Rao",company:"Nodu",contactid:"2",contactphone:"7406262728" ,followupdate:"2022-10-02 09:00:00","note":"Arrange meeting","status":"SCHEDULED","outcome":{note:"","income":"",expense:""},"objective":"PRODUCT LAUNCH","ObjectiveID":1},
    {reminderid:"10",contact:"Subramani",company:"total-sols",contactid:"3",contactphone:"9845068263" ,followupdate:"2022-10-02 09:00:00","note":"Demo","status":"EXECUTED","outcome":{note:"","income":"",expense:""},"objective":"NEWYEAR CAMPAIGN","ObjectiveID":2},
    {reminderid:"11",contact:"Keshavan",company:"Automation and Energy Solutions",contactid:"4",contactphone:"9019924922" ,followupdate:"2022-10-02 09:00:00","note":" It will also need a function to set the state to a color, wait for a second, and then set it back to the default value.","status":"DIFFERED","outcome":{note:"",income:"",expense:""},"objective":"PRODUCT LAUNCH","ObjectiveID":3},
    {reminderid:"12",contact:"Dr.P.Ramakanthkumar",company:"RV College of Engineering",contactid:"1",contactphone:"9886309520" ,followupdate:"2022-10-02 09:00:00","note":"Compliance meeting","status":"CANCELLED","outcome":{note:"","income":"",expense:""},"objective":"PRODUCT LAUNCH","ObjectiveID":4},
]
const ReminderService ={
    ListMyRemindersForContact:(contact_id)=>{
        let data = {"contact_id" : contact_id };
        return axios.post("/followup/listForContact",data);
    },
    addMyReminderForContact:(details)=>{
        let data = {
            "contact_id":details.contact_id,
            "followupdate":details.followupdate,
            "note":details.note,
            "status":details.status,
            "objective_id": details.objective_id
        };
        return axios.post("/followup",data);
    },
    updateMyReminderStatusForContact:(contact_id,status,note,outcome,followupid)=>{
        //status:""EXECUTED"",outcome: json
        let data = {
            "contact_id":contact_id,
            "status": status,
            "outcome" :outcome,
            "followupid":followupid,
            "note":note
        };
        return axios.put("/followup/updateFollowupStatusForContact",data);
    },
    getOutcomeForReminder:(outcomeID)=>{
        return axios.get("/outcome/" +outcomeID);
    },
    listMyRemindersBetweenDates(fromDate,toDate){
        let data ={"fromDate":fromDate,"toDate":toDate};
        return axios.post("followup/listFollowupsBetweenDates",data);
    },
    listMyRemindersForObjective(ObjectiveName){
        return ContactReminders.filter((reminder)=>{
            return(reminder.objective == ObjectiveName)
        })
    }

}

export default ReminderService;