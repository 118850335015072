import React,{useEffect, useState} from 'react';
import { Button,  Typography, } from '@mui/material';
import { Person2,EventNote, CrisisAlert,Camera,CameraOutlined} from '@mui/icons-material';
import {  useNavigate } from 'react-router-dom';
import TestService from './API/TestService';




  //mx: 'auto' sx={{ top:'50%',height:'50%', width: 108, height: 108}} Person : sx={{width:"60%",height:"60%"}}
function Home(props) {
    const[menuOpen, setMenuOpen] = React.useState(false);
    const wrapper = document.getElementById("cn-wrapper");
    function toggleMenu()
    {
        setMenuOpen(!menuOpen); 
    }
    
    React.useEffect(()=>
    {
        if(wrapper) 
        {
        if(menuOpen)
            wrapper.classList.add("opened-nav");
        else
            wrapper.classList.remove("opened-nav");
        }
    },[menuOpen]);

    const navigate=  useNavigate(); 

    const gotoContact = (event)=>{
        navigate("/contact");
        event.preventDefault();
    }

    const gotoFollowup = (event)=>{
        navigate("/followup");
        event.preventDefault();
    }

    const gotoObjective = (event)=>{
        navigate("/objective");
        event.preventDefault();
    }
    
    const click = (event)=>{
        TestService.greet();
        // <Button onClick={click}>Test</Button>
    }
    return (
        <div className="container">
            
            <ul id="menu">
               <a className="menu-button " id="open-menu" href="#menu" title="Show navigation"> 
                    <Camera sx={{m:7}} fontSize='large'/>
               </a>
               <a className="menu-button " href="#0" title="Hide navigation">
                    <CameraOutlined sx={{m:7}}fontSize='large'/>
               </a>
               
               <li className="menu-item">
                   <a href="/contact">
                        <Person2 sx={{ml:2,mt:-1}}fontSize='large'/>
                        <Typography sx={{ml:1, mt:-1}} color='white' variant='subtitle2' >Contacts</Typography>
                   </a>
               </li>
               <li className="menu-item">
                   <a href="/followup">
                        <EventNote sx={{ml:2,mt:-1}}fontSize='large'/>
                        <Typography sx={{ml:1, mt:-1}} color='white' variant='subtitle2' >Followup</Typography>
                   </a>
               </li>
               <li className="menu-item">
                   <a href="/objective">
                   <CrisisAlert sx={{ml:2,mt:-1}}fontSize='large'/>
                   <Typography sx={{ml:1, mt:-1}} color='white' variant='subtitle2' >Objective</Typography>
                   </a>
               </li>
               
           </ul>
        </div>
    );
}

export default Home;                                     