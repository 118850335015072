import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader,Box,Grid,TextField,Button,Paper,ListItem,Chip, Typography, IconButton } from '@mui/material';
import {ObjectiveType} from '../Utils/AppOptions';
import { useNavigate } from 'react-router-dom';
import ObjectiveService from '../API/ObjectiveService';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { Refresh } from '@mui/icons-material';

function ObjectiveDetails(props) {
    const types = ObjectiveType;
    const [name,setName]=useState('');
    const [notes,setNotes] = useState('');
    const navigate = useNavigate();
    const [objectives,setObjectives]=useState([]);

    useEffect(()=>{
        ObjectiveService.listObjectivesForUser().then((response)=>{
            setObjectives(response.data);
        })
    },[])

    const save = (e)=>{
        var data = {"title":name,"description":notes};
        ObjectiveService.addObjectiveForUser(data);
        setName("");
        setNotes("");
        reload();
    }
    const reload = () =>{
        ObjectiveService.listObjectivesForUser().then((response)=>{
            setObjectives(response.data);
        })
    }
    const cancel = (e)=>{
        navigate(-1)
    }

    const handleDelete = (chipToDelete) => () => {
        //setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    return (
        <>
            <Box sx={{mt:2, mx:'auto',height: 75,width:800}}>
            <Typography variant='h6'> Objective Details</Typography>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="Name"
                                label="Name"
                                name="Name"
                                onChange={(e)=>{setName(e.target.value)}}
                                variant='standard'
                                value={name}
                                autoFocus
                                />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="normal"
                                multiline
                                maxRows={4}
                                fullWidth
                                id="notes"
                                label="Notes"
                                name="notes"
                                onChange={(e)=>{setNotes(e.target.value)}}
                                variant='standard'
                                value={notes}
                                autoFocus
                                />
                        </Grid>
                    </Grid>
                    <Button size="small" onClick={(e)=>save(e)} variant="contained" sx={{mr:1}}>Save</Button>
                    <Button size="small" onClick={(e)=>cancel(e)} variant="contained">Cancel</Button>
                </CardContent>
            </Card>
            <Typography variant='h6'>Exising Objectives <IconButton onClick={(e)=>{reload()}}><Refresh /></IconButton></Typography>
            <Grid container>
                {
                    objectives.map((objective,i)=>{
                        return (
                          <Grid item key={i}>
                            <Chip
                              sx={{m:1}}
                              icon={<CrisisAlertIcon />}
                              label={objective.title}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        );
                    })
                }
            </Grid>
            </Box>
        </>
    );
}

export default ObjectiveDetails;