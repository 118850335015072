import React from 'react';
import {Outlet,useNavigate} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {AppBar,Toolbar,Typography,BottomNavigation,BottomNavigationAction,Box,Grid,IconButton }from '@mui/material';
import Paper from '@mui/material/Paper';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Home, DateRange, FactCheck,CrisisAlert,Palette} from '@mui/icons-material';

function Followup(props) {
    /*
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <Box sx={{ width: 500,mx:'auto',height: 75 }}>
                    <BottomNavigation
                        showLabels
                        onChange={(event) => {
                          
                        }}
                    >
                        <BottomNavigationAction label="Between Dates"  onClick={(e)=>{navigate("search")}} icon={<DateRange color="primary" fontSize='large'/>} />
                        <BottomNavigationAction label="Objective" onClick={(e)=>{navigate("objective")}} icon={<CrisisAlert color="primary" fontSize='large' />} />
        
                        <BottomNavigationAction label="home" onClick={(e)=>{navigate("/home")}} icon={<Home color="primary" fontSize='large' />} />
                    </BottomNavigation>
                </Box>
            </Paper>
    */
    const navigate = useNavigate();
    return (
        <>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                    <EventNoteIcon sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit" >
                        Followup
                    </Typography>
                    <Grid container
                        direction="row"
                        justifyContent="end"
                        alignItems="center">
                        
                            <IconButton onClick={(e)=>{navigate("search")}}>
                                <DateRange sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton >
                                <CrisisAlert onClick={(e)=>{navigate("objective")}} sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton >
                                <Palette onClick={(e)=>{navigate("../followup")}} sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton >
                                <Home  onClick={(e)=>{navigate("/home")}}  sx={{color:'white'}}/>
                            </IconButton>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Outlet />
        </>
    );
}

export default Followup;