import React from 'react';
import { Box, Typography } from '@mui/material';
import Apptag from '../Utils/Apptag';
import ApptagDescription from '../Utils/ApptagDescription';

function ContactsCloudCreate(props) {

    return (
        <>
            <Box sx={{mt:2, mx:'auto',height: 75,width:800}}>
                <Typography variant='h6'>New Tag</Typography>
                <Apptag />
                <Typography sx={{mt:2}} variant='subtitle2'>Category Description</Typography>
                <ApptagDescription />
            </Box>
        </>
    );
}

export default ContactsCloudCreate;