import React,{useState} from 'react';
import {Outlet,useNavigate} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {AppBar,Toolbar,Typography,BottomNavigation,BottomNavigationAction,Box, IconButton,Grid }from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';
import { PersonAdd, PersonSearch,LocalOffer,Home, Style,Palette, Label, SensorOccupied} from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import { yellow } from '@mui/material/colors';

function Contacs(props) {
    const [value,setValue]= useState("Hello");
    const navigate = useNavigate();
    return (
        <>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                    <ContactsIcon sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit" >
                        Contacts
                    </Typography>
                    <Grid container
                        direction="row"
                        justifyContent="end"
                        alignItems="center">
                        
                            <IconButton onClick={(e)=>{navigate("create")}}>
                                <PersonAdd sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("OCR")}}>
                                <SensorOccupied sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("search")}}>
                                <PersonSearch  sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("cloudSearch")}} >
                                <LocalOffer  sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("cloudCreate")}}>
                                <Style  sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("summary")}}>
                                <Palette  sx={{color:'white'}}/>
                            </IconButton>
                            <IconButton onClick={(e)=>{navigate("/home")}}>
                                <Home    sx={{color:'white'}}/>
                            </IconButton>
                    </Grid>
                    
                </Toolbar>
            </AppBar>
            <Outlet />
        </>
    );
}

export default Contacs;