import React from 'react';
import {createTheme,ThemeProvider} from '@mui/material/styles';
import Container from '@mui/material/Container';
import { CssBaseline,Box,Avatar,Typography,TextField,Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


function Login(props) {
    const navigate = useNavigate();
    const theme = createTheme();
    const handleSubmit = (e)=>{
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        localStorage.setItem("phoneno",data.get('phoneno'));
        navigate("/home");

    }
    return (
        <ThemeProvider theme={theme}>
            <Container component={"main"} maxWidth="xs">
                <CssBaseline />
                <Box sx={{marginTop: 8,display: 'flex',flexDirection: 'column',alignItems: 'center'}} />
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phoneno"
                    label="Phone Number"
                    name="phoneno"
                    autoComplete="phoneno"
                    autoFocus
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Sign In
                    </Button>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Login;