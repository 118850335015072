import React, { useEffect, useState } from 'react';
import { Box,TextField,Grid, IconButton, Typography, Card, Dialog, CardContent,Popover,Button,Checkbox } from '@mui/material';
import { AccessTime, CalendarToday, Search } from '@mui/icons-material';
import ReminderService from '../API/ReminderService';
import { getEventStatusIcon } from '../Utils/AppOptions';
import DlgUpdateFollowupStatus,{openDialog} from './DlgUpdateFollowupStatus';
import { grey } from '@mui/material/colors';


function DaywiseFollowup(props) {
    const [fromDate,setFromDate] = useState('');
    const [toDate,setToDate] = useState('');
    const [followups,setFollowups] = useState([]);
    const [cfollowup,setCFollowup] = useState(0);
    const [scheduledStatus,setscheduledStatus]=useState(true);
    const [executedStatus,setexecutedStatus]=useState(true);
    const [differedStatus,setdifferedStatus]=useState(true);
    const [cancelledStatus,setcancelledStatus]=useState(true);
    const [rawData,setRawData]=useState([]);

    //Modal Dialog setup
    const [openDlg,setOpenDlg]=useState(false);
    //popup setup
    const [anchorEl, setAnchorEl]=useState(null);
    const [openPopup,setOpenPopup]=useState(false);
    const [iconButtonID,setIconButtonID]= useState(undefined);
    const [outcomeText,setOutcomeText]= useState('');

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month,day].join('-');
    }

    function addDays(theDate, days) {
        return new Date(theDate.getTime() + days*24*60*60*1000);
    }

    useEffect(()=>{
        const today = new Date();
        var lastweek = addDays(new Date(),-7);
        setFromDate(formatDate(lastweek));
        setToDate(formatDate(today));
        loadData(formatDate(lastweek),formatDate(today));
        
    },[])

    const loadData = (fromDate,Todate)=>{
        ReminderService.listMyRemindersBetweenDates(fromDate,Todate).then((response)=>{
            setRawData(response.data);
            setFollowups(response.data);
            console.log(response.data);
        })
    }


    //Modal Dialog realted methods
    const updateFollowupStatus = (response) =>{
        if(response.cmd=="save"){
            console.log(" contact_id " + cfollowup.contact_id);
            console.log("status " + response.status);
            console.log("note " + response.note);
            console.log("data " + JSON.stringify(response.data));
            console.log("remider id " + cfollowup.id);
            ReminderService.updateMyReminderStatusForContact(cfollowup.id,response.status,response.note,JSON.stringify(response.data),cfollowup.id);
        }
        setOpenDlg(false);
    }

    const showDialogUpdateStatus = (event,followup) =>{
        setCFollowup(followup);

        console.log(followup);
        setOpenDlg(true);
        event.preventDefault();
    }

    const handleDlgClose = ()=>{
        setOpenDlg(false);
    }
    //Popup related methods
    const handleShowOutcomeClick = (event,buttonID,outcome) => {
        setAnchorEl(event.currentTarget);
        setIconButtonID(buttonID);
        ReminderService.getOutcomeForReminder(outcome).then((response)=>{
            setOutcomeText("Outcome : " + response.data.note);
        })
        setOpenPopup(true);
    };
    const handleShowOutcomeClose = () => {
        setOpenPopup(false);
        setAnchorEl(null);
        setIconButtonID(undefined);
        setOutcomeText("");
    };

    // Filter Checkbox related methods
    const handleScheduledFilteredStatus= (event) =>{
        setscheduledStatus(event.target.checked);
        event.preventDefault();
    }
    const handleExecutedFilteredStatus = (event) =>{
        setexecutedStatus(event.target.checked);
        event.preventDefault();
    }
    const handleDifferedFilteredStatus = (event) =>{
        setdifferedStatus(event.target.checked);
        event.preventDefault();
    }
    const handleCancelledFilteredStatus = (event) =>{
        setcancelledStatus(event.target.checked);
        event.preventDefault();
    }
    
    const doFilter = (event)=>{
        setFollowups([]);
        let filteredData = rawData.filter((data)=>{
            return ((data.status=="SCHEDULED") && scheduledStatus) || ((data.status=="EXECUTED") && executedStatus) || ((data.status=="DIFFERED") && differedStatus) || ((data.status=="CANCELLED") && cancelledStatus);
        })
        setFollowups(filteredData);
        event.preventDefault();
    }
     
    return (
        <>
            <Box container sx={{mt:2, mx:'auto', height: 75,width:800}}>
                <Typography variant='h6'>Followup List</Typography>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <TextField helperText={'From Date'}  sx={{mr:2 }} variant='standard' value={fromDate} onChange={(e)=>{setFromDate(e.target.value)}} type={'date'}  />
                        <TextField helperText={'To Date'} variant='standard' value={toDate} onChange={(e)=>{setToDate(e.target.value)}} type={'date'} />
                        <IconButton onClick={()=>{
                            loadData(fromDate,toDate);
                        }}>
                            <Search color='primary' />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Grid container  alignItems="flex-end" columnSpacing={2}>
                                <Grid item>
                                    <Checkbox checked={scheduledStatus} icon={getEventStatusIcon("SCHEDULED",grey['500'])} checkedIcon={getEventStatusIcon("SCHEDULED","primary")} onChange={handleScheduledFilteredStatus} />
                                </Grid>
                                <Grid item>
                                <Checkbox checked={executedStatus} icon={getEventStatusIcon("EXECUTED",grey['500'])} checkedIcon={getEventStatusIcon("EXECUTED","primary")} onChange={handleExecutedFilteredStatus} />
                                </Grid>
                                <Grid item>
                                    <Checkbox checked={differedStatus} icon={getEventStatusIcon("DIFFERED",grey['500'])} checkedIcon={getEventStatusIcon("DIFFERED","primary")} onChange={handleDifferedFilteredStatus} />
                                </Grid>
                                <Grid item>
                                    <Checkbox checked={cancelledStatus} icon={getEventStatusIcon("CANCELLED",grey['500'])} checkedIcon={getEventStatusIcon("CANCELLED","primary")} onChange={handleCancelledFilteredStatus} />  
                                </Grid>
                                <Button onClick={doFilter}>Filter</Button>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Grid container direction={'row'} rowSpacing={2} columnSpacing={2} >
                    {
                        followups.map((followup,idx)=>{
                            return(
                                <Grid key={idx} item maxWidth={200} height={210} sx={{display:"flex"}}>
                                    <Card raised={true} sx={{ borderRadius: '6px'}}>
                                        <CardContent>
                                            <Grid container direction={"row"} columnSpacing={1}  >
                                                <Grid item xs={10}>
                                                    <Typography variant='subtitle2' >{followup.title}</Typography>
                                                </Grid>
                                                <Grid item xs={2} justifyContent="flex-end">
                                                    {
                                                        (followup.status == "SCHEDULED") ?
                                                            <IconButton size='small' onClick={(e)=>{showDialogUpdateStatus(e,followup)}}>
                                                                {getEventStatusIcon(followup.status)}
                                                            </IconButton>
                                                        : 
                                                            <Box>
                                                            <IconButton aria-describedby={'popover'+followup.reminderid} size='small' onClick={(e)=>{handleShowOutcomeClick(e,'popover'+followup.reminderid,followup.outcome_id)}} >
                                                                {getEventStatusIcon(followup.status,"secondary")}
                                                            </IconButton>
                                                                
                                                            </Box>   
                                                    }
                                                </Grid>
                                    
                                            
                                            </Grid>
                                            
                                            <Typography fontSize={12} fontWeight={'bold'}>{followup.company}</Typography>
                                            <Typography fontSize={12} fontWeight={'bold'}>{followup.name} -  {followup.mobile}</Typography>
                                           
                                            
                                            <Box component='span' fontSize={12} >
                                                <CalendarToday sx={{mr:1}} fontSize='inherit' />
                                                {followup.followupdate.split(" ")[0]} @ {followup.followupdate.split(" ")[1]}
                                            </Box>  
                                            <Box fontSize={12} sx={{display: "flex",height: 30,
                                                        overflow: "hidden", overflowY: "scroll"}} >
                                                <Typography variant='inherit'>{followup.note}</Typography><br/>
                        
                                            </Box>
                        
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Popover id={iconButtonID} open={openPopup} anchorEl={anchorEl}
                    onClose={handleShowOutcomeClose} anchorOrigin={{ vertical: 'bottom',horizontal: 'left'}} >
                    <Typography sx={{ p: 2 }}>{outcomeText}</Typography>
                </Popover>
            </Box>
            <Dialog open={openDlg} onClose={handleDlgClose}>
                <DlgUpdateFollowupStatus onSelection={updateFollowupStatus} objectiveID={cfollowup.title}/>
            </Dialog>
            
        </>
    );
}

export default DaywiseFollowup;