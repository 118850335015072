import React,{useEffect, useState} from 'react';
import { Box,Typography,Grid,Button,IconButton,Checkbox,Select,MenuItem,Card,CardContent,Popover,Dialog } from '@mui/material';
import { getEventStatusIcon } from '../Utils/AppOptions';
import {Search, AccessTime, CalendarToday} from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import ObjectiveService from '../API/ObjectiveService';
import ReminderService from '../API/ReminderService';
import DlgUpdateFollowupStatus from './DlgUpdateFollowupStatus';

function ObjectivewiseFollowup(props) {
    //Filter related state variables
    const [scheduledStatus,setscheduledStatus]=useState(true);
    const [executedStatus,setexecutedStatus]=useState(true);
    const [differedStatus,setdifferedStatus]=useState(true);
    const [cancelledStatus,setcancelledStatus]=useState(true);

    const [objectives,setObjectives] = useState([]);

    const [followups,setFollowups] = useState([]);
    const [currentFollowup,setCurrentFollowup] = useState({});
    const [rawData,setRawData] = useState([]);
     //Modal Dialog setup
     const [openDlg,setOpenDlg]=useState(false);
     //popup setup
     const [anchorEl, setAnchorEl]=useState(null);
     const [openPopup,setOpenPopup]=useState(false);
     const [iconButtonID,setIconButtonID]= useState(undefined);
     const [outcomeText,setOutcomeText]= useState('');
  

    // Filter Checkbox related methods
    const handleScheduledFilteredStatus= (event) =>{
        setscheduledStatus(event.target.checked);
        event.preventDefault();
    }
    const handleExecutedFilteredStatus = (event) =>{
        setexecutedStatus(event.target.checked);
        event.preventDefault();
    }
    const handleDifferedFilteredStatus = (event) =>{
        setdifferedStatus(event.target.checked);
        event.preventDefault();
    }
    const handleCancelledFilteredStatus = (event) =>{
        setcancelledStatus(event.target.checked);
        event.preventDefault();
    }

    useEffect(()=>{
        ObjectiveService.listObjectivesForUser().then((response)=>{
            setObjectives(response.data);
        });
    },[])

    //Get data for given objective
    const handleObjectiveChange =(event)=>{
        let selectedObjective = event.target.value;
        var data =[];
        if(selectedObjective!=""){
            data = ReminderService.listMyRemindersForObjective(selectedObjective);
        }
        setRawData(data);
        setFollowups(data);
    }

    //Modal Dialog realted methods
    const updateFollowupStatus = (response) =>{
        setOpenDlg(false);
     }
 
     const showDialogUpdateStatus = (event,followup) =>{
         setCurrentFollowup(followup);
         setOpenDlg(true);
         event.preventDefault();
     }
 
     const handleDlgClose = ()=>{
         setOpenDlg(false);
     }
     //Popup related methods
     const handleShowOutcomeClick = (event,buttonID,outcome) => {
         setAnchorEl(event.currentTarget);
         setIconButtonID(buttonID);
         setOutcomeText("Outcome : " + outcome);
         setOpenPopup(true);
     };
     const handleShowOutcomeClose = () => {
     setOpenPopup(false);
     setAnchorEl(null);
     setIconButtonID(undefined);
     setOutcomeText("");
     };

    const doFilter = (event)=>{
        
        setFollowups([]);
        let filteredData = rawData.filter((data)=>{
            return ((data.status=="SCHEDULED") && scheduledStatus) || ((data.status=="EXECUTED") && executedStatus) || ((data.status=="DIFFERED") && differedStatus) || ((data.status=="CANCELLED") && cancelledStatus);
        })
        setFollowups(filteredData);
    
        event.preventDefault();
    }

    return (
        <>
        <Box container sx={{mt:2, mx:'auto', height: 75,width:800}}>
            <Typography variant='h6'>Objectivewise followups</Typography>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Select variant="standard" onChange={handleObjectiveChange} label="Objective" margin='dense'>
                        <MenuItem value="">
                            <em></em>
                        </MenuItem>
                        {
                            objectives.map((objective,i)=>{
                                return(<MenuItem key={i}  value={objective.title}>{objective.title}</MenuItem>)
                            })
                        }
                    </Select>
                    
                </Grid>
                <Grid item>
                    <Grid container  alignItems="flex-end" columnSpacing={2}>
                            <Grid item>
                                <Checkbox checked={scheduledStatus} icon={getEventStatusIcon("SCHEDULED",grey['500'])} checkedIcon={getEventStatusIcon("SCHEDULED","primary")} onChange={handleScheduledFilteredStatus} />
                            </Grid>
                            <Grid item>
                            <Checkbox checked={executedStatus} icon={getEventStatusIcon("EXECUTED",grey['500'])} checkedIcon={getEventStatusIcon("EXECUTED","primary")} onChange={handleExecutedFilteredStatus} />
                            </Grid>
                            <Grid item>
                                <Checkbox checked={differedStatus} icon={getEventStatusIcon("DIFFERED",grey['500'])} checkedIcon={getEventStatusIcon("DIFFERED","primary")} onChange={handleDifferedFilteredStatus} />
                            </Grid>
                            <Grid item>
                                <Checkbox checked={cancelledStatus} icon={getEventStatusIcon("CANCELLED",grey['500'])} checkedIcon={getEventStatusIcon("CANCELLED","primary")} onChange={handleCancelledFilteredStatus} />  
                            </Grid>
                            <Button onClick={doFilter}>Filter</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={'row'} rowSpacing={2} columnSpacing={2} >
                {
                    followups.map((followup,idx)=>{
                        return(
                            <Grid item maxWidth={200} height={210} sx={{display:"flex"}}>
                                <Card raised={true} sx={{ borderRadius: '6px',display:"flex"}} >
                                    <CardContent>
                                        <Grid container direction={"row"} columnSpacing={1}>
                                        <Grid xs={10} item>
                                            <Typography variant='subtitle2' >{followup.objective}</Typography>
                                        </Grid>
                                        <Grid xs={2} item justifyContent="flex-end">
                                            {
                                                (followup.status == "SCHEDULED") ?
                                                    <IconButton size='small' onClick={(e)=>{showDialogUpdateStatus(e,followup)}}>
                                                        {getEventStatusIcon(followup.status)}
                                                    </IconButton>
                                                : 
                                                    <Box>
                                                    <IconButton aria-describedby={'popover'+followup.reminderid} size='small' onClick={(e)=>{handleShowOutcomeClick(e,'popover'+followup.reminderid,followup.outcome)}} >
                                                        {getEventStatusIcon(followup.status,"secondary")}
                                                    </IconButton>
                                                        
                                                    </Box>   
                                            }
                                        </Grid>
                                        
                                        
                                        
                                        </Grid>
                                        
                                        <Typography fontSize={12} fontWeight={'bold'}>{followup.company}</Typography>
                                        <Typography fontSize={12} fontWeight={'bold'}>{followup.contact} - {followup.contactphone}</Typography>
                                        
                                        
                                        <Box component='span' fontSize={12} >
                                            <CalendarToday sx={{mr:1}} fontSize='inherit' />
                                            {followup.followupdate.split(" ")[0]} @ {followup.followupdate.split(" ")[1]}
                                        </Box>  
                                        <Box fontSize={12} sx={{display: "flex",height: 30,
                                                    overflow: "hidden", overflowY: "scroll"}} >
                                            <Typography variant='inherit'>{followup.note}</Typography><br/>
                    
                                        </Box>
                    
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Popover id={iconButtonID} open={openPopup} anchorEl={anchorEl}
                onClose={handleShowOutcomeClose} anchorOrigin={{ vertical: 'bottom',horizontal: 'left'}} >
                <Typography sx={{ p: 2 }}>{outcomeText}</Typography>
            </Popover>
            <Dialog open={openDlg} onClose={handleDlgClose}>
                <DlgUpdateFollowupStatus onSelection={updateFollowupStatus} />
            </Dialog>
        </Box>
            
        </>
    );
}

export default ObjectivewiseFollowup;