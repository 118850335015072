import React,{useEffect,useState} from 'react';
import { Box,Grid,IconButton,Typography,ButtonGroup,Button,Chip } from '@mui/material';
import {ArrowBack} from '@mui/icons-material';
import { useLocation,useNavigate } from 'react-router-dom';
import TagService from '../API/TagService';
import ContactService from '../API/ContactService';
import Apptag from '../Utils/Apptag';
import { getICon } from '../API/AppIconService';


const initContactDetails={name:"Subramani",company:"total-sols",designation:"Founder",email:"as@totalsols.in",phone:"9845068263",tagname:"VBF",categoryname:"Groups"};

function ContactTags(props) {
    const loc = useLocation();
    const navigate = useNavigate();
    const [contactDetails,setContactDetails]=useState(initContactDetails);
    const [mytags,setMytags] = useState([]);
    const [contactTags,setContactTags] = useState([]);

    useEffect(()=>{
        setContactDetails(loc.state.record);
        refreshTags();
    },[])

    const CreateNewTag= (selectedCategory,tagname)=>{
        TagService.addCatTagForUser(selectedCategory,tagname,"PUBLIC").then((response)=>{
            ContactService.addTagForMyContact(contactDetails.mobile,response.data.id).then((response)=>{
                refreshTags();
            })
        });
    }
    const refreshTags = ()=>{
        TagService.listCatTagsForUser().then((response)=>{
            setMytags(response.data);
        })
        ContactService.listTagsForMyContact(loc.state.record.mobile).then((response)=>{
            setContactTags(response.data);
        })
    }
    const addTagToContact=(tag)=>{
        ContactService.addTagForMyContact(contactDetails.mobile,tag.id).then((response)=>{
            refreshTags();
        });
    }

    return (
        <>
            <Box sx={{mt:2, mx:'auto',height: 75,width:800 }}>
                <Grid container justifyContent="space-between" sx={{mb:2}}>
                    <Grid>
                        <IconButton onClick={()=>navigate(-1)}><ArrowBack /></IconButton>
                        <Typography variant='h6'>Tags for {contactDetails.name}</Typography>
                    </Grid>
                    <Grid>
                        <Typography variant='subtitle1'>{contactDetails.company}</Typography>
                        <Typography variant='subtitle2'>{contactDetails.designation}</Typography>
                        <Typography variant='subtitle2'>{contactDetails.mobile}</Typography>
                    </Grid>
                </Grid>
                <Typography>Assigned tags</Typography>
                {
                    typeof contactTags!== 'undefined' ?
                    contactTags.map((contact,i)=>{
                        return(
                            <Chip key={i} sx={{m:1}} icon={getICon(contact.categoryname)} label={contact.tagname}
                            variant="outlined" size="small" onClick={(e)=>{alert("clicked")}}
                            />
                        )
                    })
                    : null
                     
                }
                <Typography>Available tags</Typography>
                {
                    typeof mytags!== 'undefined' ?
                    mytags.map((item,i)=>(
                        <Chip key={i} sx={{m:1}} color="secondary" icon={getICon(item.categoryname,"secondary")} label={item.tagname}
                        variant="outlined" size="small" onClick={(e)=>{addTagToContact(item)}}
                        />
                    ))
                    :null
                }
                <Typography sx={{mt:2}}>New tag</Typography>
                <Apptag onSelection={CreateNewTag}/>
            </Box>
            
        </>
    );
}

export default ContactTags;