import React, { useEffect, useState } from 'react';
import { Grid,Typography,TableContainer,Table,TableHead,TableCell,TableBody,TableRow,TextField, Button } from '@mui/material';
import ObjectiveService from '../../API/ObjectiveService';
import { useSearchParams } from 'react-router-dom';


function GenericFinancialOutcomeRecords(props) {
    const [fromDate,setFromDate] = useState('');
    const [toDate,setToDate] = useState('');
    const [objectivid,setObjectiveid] = useState(0);

    const [outcomes,setOutcomes] = useState([]);
    const [objective,setObjective] = useState('');
    const [searchParams] = useSearchParams();


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month,day].join('-');
    }

    function addDays(theDate, days) {
        return new Date(theDate.getTime() + days*24*60*60*1000);
    }

    useEffect(()=>{
        setObjectiveid(searchParams.get("objective_id"));
        const today = new Date();
        var lastweek = addDays(new Date(),-7);
        setFromDate(formatDate(lastweek));
        setToDate(formatDate(today));
        
    },[])

    const getData=()=>{
        ObjectiveService.listOutcomeForObjectives(objectivid,fromDate,toDate).then((response)=>{
            if (response.data.length > 0){
                if(response.data[0].title==="REIKI"){
                    setOutcomes(response.data)
                    setObjective(response.data[0].title); 
                }
            }
        })
    }

    return (
        <>
            <Grid>
                <Typography variant='subtitle1'>Period</Typography>
                <TextField helperText={'From Date'}  sx={{mr:2 }} variant='standard' value={fromDate} onChange={(e)=>{setFromDate(e.target.value)}} type={'date'}  />
                <TextField helperText={'To Date'} variant='standard' value={toDate} onChange={(e)=>{setToDate(e.target.value)}} type={'date'} />
                <Button onClick={getData}>search</Button>
            </Grid>
            <Grid>
                <Typography variant='subtitle1'>Outcomes of {objective}</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Purpose</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Outcome</TableCell>
                                <TableCell align="right">Income</TableCell>
                                <TableCell align="right">Expense</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { 
                                outcomes.map((outcome,i)=>{
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{outcome.name}</TableCell>
                                            <TableCell>{outcome.mobile}</TableCell>
                                            <TableCell>{outcome.note}</TableCell>
                                            <TableCell>{outcome.followupdate}</TableCell>
                                            <TableCell>{outcome.observation}</TableCell>
                                            <TableCell align="right">{JSON.parse(outcome.outcome).income}</TableCell>
                                            <TableCell align="right">{JSON.parse(outcome.outcome).expense}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
        
    );
}

export default GenericFinancialOutcomeRecords;