import React from 'react';
import ReactDOM from 'react-dom/client';
import {Routes,Route,BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './components/Login';
import Home from './components/Home';
import Contacts from './components/Contacts/Contacs';
import ContactSearch from './components/Contacts/ContactSearch';
import ContactsCloudCreate from './components/Contacts/ContactsCloudCreate';
import ContactCloudSearch from './components/Contacts/ContactCloudSearch';
import ContactSearchResult from './components/Contacts/ContactSearchResult';
import ContactsDetail from './components/Contacts/ContactsDetail';
import ContactFollowups from './components/Contacts/ContactFollowups';
import ContactTags from './components/Contacts/ContactTags';
import ContactOCR from './components/Contacts/ContactOCR';
import ContactsSummary from './components/Contacts/ContactsSummary';

import Followups from './components/Followups/Followups';
import DaywiseFollowup from './components/Followups/DaywiseFollowup';

import Objectives from './components/Objectives/Objectives';
import ObjectiveDetails from './components/Objectives/ObjectiveDetails';
import ObjectivewiseFollowup from './components/Followups/ObjectivewiseFollowup';
import ObjectiveAnalysis from './components/Objectives/ObjectiveAnalysis';
import GenericFinancialOutcomeRecords from './components/Objectives/OutcomeDataCollection/GenericFinancialOutcomeRecords';
import AcupunctureSessionRecords from './components/Objectives/OutcomeDataCollection/AcupunctureSessionRecords';
import DietitianSessionRecords from './components/Objectives/OutcomeDataCollection/DietitianSessionRecords';
import Blank from './components/Blank';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/home' element={<Home />}></Route>
        <Route path='contact' element={<Contacts />}>
          <Route path='search' element={<ContactSearch />}>
            <Route path='searchresult' element={<ContactSearchResult />}></Route>
          </Route>
          <Route path='cloudSearch' element={<ContactCloudSearch />}>
            <Route path='searchresult' element={<ContactSearchResult />}></Route>
          </Route>
          <Route path='cloudCreate' element={<ContactsCloudCreate />}></Route>
          <Route path='create' element={<ContactsDetail />}></Route>
          <Route path='followup' element={<ContactFollowups />}></Route>
          <Route path='tag' element={<ContactTags />}></Route>
          <Route path='OCR' element={<ContactOCR />}></Route>
          <Route path="summary" element={<ContactsSummary />} ></Route>
        </Route>
        <Route path='followup' element={<Followups />}>
          <Route path='search' element={<DaywiseFollowup />}></Route>
          <Route path='objective' element={<ObjectivewiseFollowup />}></Route>
        </Route>
        <Route path='objective' element={<Objectives />}>
          <Route path='create' element={<ObjectiveDetails />}></Route>
          <Route path='analysis' element={<ObjectiveAnalysis />}>
            <Route path='GenericFinancialOutcomeRecords' element={<GenericFinancialOutcomeRecords />}></Route>
            <Route path='AcupunctureSessionRecords' element={<AcupunctureSessionRecords />}></Route>
            <Route path='DietitianSessionRecords' element={<DietitianSessionRecords />}></Route>
            <Route path='Blank' element={<Blank />}></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);


