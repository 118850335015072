import React,{useState} from 'react';
import { TextField } from '@mui/material';

function GenericFinancialOutcomeDataCollection({onDataCollection}) {
    const [income,setIncome] = useState(0);
    const [expense,setExpense]= useState(0);
    const updateParent =(e)=>{
        let data = {income:income,expense:expense};
        onDataCollection(data);
        e.preventDefault();
    }
    return (
        <>
            <TextField autoFocus variant='standard' margin="dense" size='small' type={'number'} label="Expence Amount" 
                onChange={(e)=>{setExpense(e.target.value);updateParent(e)}} 
                value={expense}                      
            />
            <TextField autoFocus variant='standard' margin="dense" size='small' type={'number'} label="Received Amount" 
                onChange={(e)=>{setIncome(e.target.value);updateParent(e)}} 
                value={income}               
            />
        </>
    );
}

export default GenericFinancialOutcomeDataCollection;