import React, { useState } from 'react';
import { DialogTitle,DialogContent,DialogActions,Box,TextField,Radio,Button } from '@mui/material';
import {getEventStatusIcon} from '../Utils/AppOptions';
import GenericFinancialOutcomeDataCollection from '../Objectives/OutcomeDataCollection/GenericFinancialOutcomeDataCollection';
import AcupunctureSessionOutcome from '../Objectives/OutcomeDataCollection/AcupunctureSessionOutcome';
import DietitianSessionOutcome from '../Objectives/OutcomeDataCollection/DietitianSessionOutcome';

/*
how to use this component:
  step 1: import DlgUpdateFollowupStatus,{openDialog} from './DlgUpdateFollowupStatus';
  step 2: Create state variable
            const [openDlg,setOpenDlg]=useState(false);
  step 3: some where withing return method of the parent, 
            <Dialog open={openDlg} onClose={handleDlgClose}>
                <DlgUpdateFollowupStatus onSelection={updateFollowupStatus} />
            </Dialog>
  step 4: Implement methods
            updateFollowupStatus, this method will receive an object {"cmd":"","status":"","note":""}
            cmd will have "cancel" if user clicks on cancel button, and ignore status and note values
            cmd will have "save" if user clicks on save button
                status will have any of "EXECUTED","DIFFERED","CANCELLED" values
                note will have comment entered by the user.

*/

function DlgUpdateFollowupStatus({onSelection,objectiveID,inputData}) {
    const [outcome,setOutcome]=useState('');
    const [selectedStatus,setSelectedStatus]=useState("EXECUTED");
    const [matric,setMatric] = useState({});
  
    const handleDlgClose = (event) =>{
        const data = {cmd:"close",status:"",note:"",data:matric};
        onSelection(data);
        event.preventDefault();
    }

    const handleDlgSave = (event) =>{
        const data = {cmd:"save",status:selectedStatus,note:outcome,data:matric};
        onSelection(data);
        event.preventDefault();
    }
    
    const updateDataCollected = (data)=>{
        setMatric(data);
    }
    return (
        <>
                <DialogTitle>Update Followup : {objectiveID}</DialogTitle>
                <DialogContent>
                    <Box container flexDirection={'column'}>
                        <Box item>
                            {
                                ["EXECUTED","DIFFERED","CANCELLED"].map((status,i)=>{
                                    return(
                                        <Radio icon={getEventStatusIcon(status,"primary")} 
                                            checked={selectedStatus === status}
                                            onChange={(event)=>{ setSelectedStatus(event.target.value)}}
                                            value={status}
                                            name="radio-buttons">
                                        </Radio>
                                    )
                                })
                            }
                        </Box>
                        <Box item>
                            <TextField autoFocus variant='standard' margin="dense" size='small' label="Outcome Note" 
                                onChange={(e)=>{setOutcome(e.target.value)}} 
                                value={outcome}                      
                            />
                        </Box>
                        <Box item>
                            {(()=>{
                                 switch(objectiveID){
                                    case "ACCUPUNCTURE":
                                        return <AcupunctureSessionOutcome onDataCollection={updateDataCollected}/>
                                    case "DIET COUNSELLING":
                                        return <DietitianSessionOutcome onDataCollection={updateDataCollected} Height={inputData}/>
                                    default :
                                        return <GenericFinancialOutcomeDataCollection onDataCollection={updateDataCollected}/>
                                }
                            })()
                            }
                            
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e)=>{handleDlgClose(e)}}>Cancel</Button>
                    <Button onClick={(e)=>{handleDlgSave(e)}}>Save</Button>
                </DialogActions>
        </>  
    );
}

export default DlgUpdateFollowupStatus;