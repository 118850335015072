import React from 'react';
import {getICon} from '../API/AppIconService';

export const ObjectiveType=[
    {id:1,title:"DHARMA",icon:"Gavel"},
    {id:2,title:"ARTHA",icon:"CurrencyRupee"},
    {id:3,title:"KAAMA",icon:"Favorite"},
    {id:4,title:"MOKSHA",icon:"LockOpen"}
]

export const getObjectiveIcon = (title,color='primary')=>{
    switch (title) {
        case "DHARMA":
            return getICon("Gavel",color); 
        case "ARTHA":
            return getICon("CurrencyRupee",color);       
        case "KAAMA":
            return getICon("Favorite",color); 
        case "MOKSHA":
            return getICon("LockOpen",color);  
        default:
            return getICon("QuestionMark",color); 
    }
}

export const EventStatus=[
    "SCHEDULED","EXECUTED","DIFFERED","CANCELLED"
]

export const getEventStatusIcon = (status,color='primary')=>{
    switch (status) {
        case "SCHEDULED":
            return getICon("Event",color); 
        case "EXECUTED":
            return getICon("EventAvailable",color); 
        case "DIFFERED":
            return getICon("EventRepeat",color); 
        case "CANCELLED":
            return getICon("EventBusy",color); 
        default:
            return getICon("QuestionMark",color); 
    }
}

function AppOptions(props) {
    return (
        <div>
            
        </div>
    );
}

export default AppOptions;