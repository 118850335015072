import React,{useEffect} from 'react';
import { useNavigate} from 'react-router-dom';


function App(props) {
  const navigate = useNavigate();
  useEffect(()=>{
    let phno = localStorage.getItem("phoneno");
    if(phno === null){
      navigate("/login");
    }else{
      navigate("/home");
    }
  },[]);
 
  return (
    <div>
      
    </div>
  );
}

export default App;