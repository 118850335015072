import React, { useEffect, useState } from 'react';
import {Box,Button,ButtonGroup,IconButton,Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Apptag,{getCategoryIcon} from '../Utils/Apptag';
import { useNavigate,Outlet,createSearchParams } from 'react-router-dom';
import TagService from '../API/TagService';

function ContactCloudSearch(props) {
    const navigate = useNavigate();
    const [mytags,setMytags] = useState([]);

    const searchOnCategory = (categoryname)=>{
        navigate({pathname :"searchresult",
                  search: createSearchParams({categoryname:categoryname}).toString()
                });
    }
    const searchOnTag = (tagname)=>{
        navigate({pathname :"searchresult",
                  search: createSearchParams({tagname:tagname}).toString()
                });
    }
    const searchOnTagCategory =(tagname,categoryname)=>{
        navigate({pathname :"searchresult",
                  search: createSearchParams({tagname:tagname,categoryname:categoryname}).toString()
                });
    }

    useEffect(()=>{
        TagService.listCatTagsForUser(123).then((response)=>{
            setMytags(response.data);
        })
    },[])

    return (
        <>  
            
            <Box sx={{mt:2, mx:'auto',height: 75,width:800}}>
                {
                    mytags.map((item,i)=>(
                        <ButtonGroup key={i} size='small'>
                            <IconButton onClick={(e)=>searchOnCategory(item.categoryname)} sx={{m:-1}}>{getCategoryIcon(item.categoryname)}</IconButton>
                            <Button onClick={(e)=>searchOnTag(item.tagname)} variant='text' sx={{m:-1}}>{item.tagname} </Button>
                            <IconButton onClick={(e)=>searchOnTagCategory(item.tagname,item.categoryname)} sx={{m:0}}>{<SearchIcon />}</IconButton>
                        </ButtonGroup>
                    ))
                }
                <Typography variant='h6'>Search Result</Typography>
                <Outlet />
            </Box>
            
        </> 
    );
}

export default ContactCloudSearch;