import React,{useState} from 'react';
import { FormControlLabel,Checkbox,Box } from '@mui/material';
import { pink } from '@mui/material/colors';

function AcupunctureSessionOutcome({onDataCollection}) {
    const [pt1,setPt1]= useState(false);
    const [pt2,setPt2]= useState(false);
    const [pt3,setPt3]= useState(false);
    const [pt4,setPt4]= useState(false);
    const [pt5,setPt5]= useState(false);
    const [pt6,setPt6]= useState(false);
    const [pt7,setPt7]= useState(false);
    const [pt8,setPt8]= useState(false);
    const [pt9,setPt9]= useState(false);
    const [pt10,setPt10]= useState(false);

    const updateParent =(e)=>{
        let data = {pt1:pt1,pt2:pt2,pt3:pt3};
        onDataCollection(data);
        e.preventDefault();
    }
    return (
        <>
            <Box container>
                <FormControlLabel value={pt1} onChange={(e)=>{setPt1(e.target.value);updateParent(e)}} control={<Checkbox  />} label="Pt1" labelPlacement="bottom" /> 
                <FormControlLabel value={pt2} onChange={(e)=>{setPt2(e.target.value);updateParent(e)}} control={<Checkbox sx={{color: pink[800],'&.Mui-checked': {color: pink[600]} }} />} label="Pt2" labelPlacement="bottom" /> 
                <FormControlLabel value={pt3} onChange={(e)=>{setPt3(e.target.value);updateParent(e)}} control={<Checkbox  />} label="Pt3" labelPlacement="bottom" /> 
                <FormControlLabel value={pt4} onChange={(e)=>{setPt4(e.target.value);updateParent(e)}} control={<Checkbox  />} label="Pt4" labelPlacement="bottom" /> 
                <FormControlLabel value={pt5} onChange={(e)=>{setPt5(e.target.value);updateParent(e)}} control={<Checkbox sx={{color: pink[800],'&.Mui-checked': {color: pink[600]} }} />} label="Pt5" labelPlacement="bottom" /> 
                <FormControlLabel value={pt6} onChange={(e)=>{setPt6(e.target.value);updateParent(e)}} control={<Checkbox  />} label="Pt6" labelPlacement="bottom" /> 
                <FormControlLabel value={pt7} onChange={(e)=>{setPt7(e.target.value);updateParent(e)}} control={<Checkbox  />} label="Pt7" labelPlacement="bottom" /> 
                <FormControlLabel value={pt8} onChange={(e)=>{setPt8(e.target.value);updateParent(e)}} control={<Checkbox sx={{color: pink[800],'&.Mui-checked': {color: pink[600]} }} />} label="Pt8" labelPlacement="bottom" /> 
                <FormControlLabel value={pt9} onChange={(e)=>{setPt9(e.target.value);updateParent(e)}} control={<Checkbox  />} label="Pt9" labelPlacement="bottom" /> 
            </Box>
        </>
    );
}

export default AcupunctureSessionOutcome;