import axios from '../API/DBService';

var data = [
    {categoryname:"Loyalty",tagname:"Friends",type:"PUBLIC",id:"1"},
    {categoryname:"Call",tagname:"Coworker",type:"PUBLIC",id:"2"},
    {categoryname:"Groups",tagname:"Experts",type:"PUBLIC",id:"3"},
]

var categories = [
    {"category":"CakeSharp","description":"birthday","id":"1"},
    {"category":"Call","description":"","id":"2"},
    {"category":"WhatsApp","description":"","id":"3"},
    {"category":"Groups","description":"","id":"4"},
    {"category":"Vaccines","description":"","id":"5"},
    {"category":"LocalLibrary","description":"","id":"6"},
    {"category":"Badge","description":"","id":"7"},
    {"category":"Loyalty","description":"","id":"8"},
    {"category":"Label","description":"","id":"9"},
    {"category":"PushPin","description":"","id":"10"},
    {"category":"Handshake","description":"","id":"11"},
    {"category":"Place","description":"","id":"12"},
    {"category":"LocalDining","description":"","id":"13"},
    {"category":"LocalCafe","description":"","id":"14"}
]

var systemCategories=[
    {"category":"Gavel"},
    {"category":"CurrencyRupee"},
    {"category":"Favorite"},
    {"category":"LockOpen"},
    {"category":"QuestionMark"}, 
    {"category":"Event"}, 
    {"category":"EventAvailable"}, 
    {"category":"EventRepeat"}, 
    {"category":"EventBusy"},
    {"category":"Payment"}
]
const TagService = {
    addCatTagForUser:(argCategoryname,argTagname,argType)=>{
        let entry = {categoryname:argCategoryname,tagname:argTagname,type:argType};
        return axios.post("/tag",entry);
    },
    listCategoriesForUser:()=>{
        return axios.get("/category");
    },
    updateCategoryDiscription:(id,description)=>{
        let url = "/category/"+ id;
        let data = {"description":description};
        return axios.put(url,data);
    },
    listCatTagsForUser :()=>{
        return axios.get("/tag");
    },
    listTagSummary :() =>{
        return axios.get("/listTagSummary");
    }
}

export default TagService;



